<template>
    <div id="appCapsule">
        <div class="section mt-3 text-center">
            <div class="avatar-section">
                <a href="javascript:void(0)">
                    <img src="../assets/img/metriclogo_color.png" alt="avatar" style="width:90px;height:90px">
                    <!--                    <span class="button">-->
                    <!--                        <ion-icon name="camera-outline"></ion-icon>-->
                    <!--                    </span>-->
                </a>
            </div>
        </div>

        <div class="listview-title mt-1">Profile Settings</div>
        <ul class="listview image-listview text inset">
            <li>
                <a href="javascript:void(0)" class="item" data-bs-toggle="modal" data-bs-target="#updateProfile">
                    <div class="in">
                        <div>Profile update</div>
                        <span class="text-muted">
                            {{getUser.data.name.toLowerCase()}}
                        </span>
                    </div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" class="item" @click="verifyEmail" v-if="!getUser.data.email_is_verified">
                    <div class="in">
                        <div>E-mail verification</div>
                        <span class="fw-20">
                            <ion-icon class="text-warning" name="alert-circle-outline"></ion-icon>
                        </span>
                    </div>
                </a>
                <a href="javascript:void(0)" class="item" v-else>
                    <div class="in">
                        <div>E-mail verification</div>
                        <span class="fw-20">
                            <ion-icon class="text-success" name="checkmark-circle-outline"></ion-icon>
                        </span>
                    </div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" class="item" data-bs-toggle="modal" data-bs-target="#updateCurrency">
                    <div class="in">
                        <div>Currency</div>
                        <span class="text-primary">{{getUser.data.currency}}</span>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-1">Security</div>
        <ul class="listview image-listview text mb-2 inset">
            <li>
                <a href="javascript:void(0)" class="item" data-bs-toggle="modal" data-bs-target="#updatePassword">
                    <div class="in">
                        <div>Update Password</div>
                    </div>
                </a>
            </li>
            <li>
                <div class="item">
                    <div class="in">
                        <div>
                            2 Step Verification
                        </div>
                        <div class="form-check form-switch ms-2">
                            <input class="form-check-input" type="checkbox" id="SwitchCheckDefault3" v-model="twoFA_value"/>
                            <label class="form-check-label" for="SwitchCheckDefault3"></label>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <a href="javascript:void(0)" class="item" @click.prevent="processPassPhraseEmail">
                    <div class="in">
                        <div>Recover PassPhrase</div>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-1">Referral</div>
        <ul class="listview image-listview text mb-2 inset">
            <li>
                <a href="javascript:void(0)" class="item" @click="copyReferralLink">
                    <div class="in">
                        <div>Copy Referral link</div>
                        <span class="text-success">
                            <ion-icon name="copy-outline"></ion-icon>
                        </span>
                    </div>
                </a>
            </li>
        </ul>
        <p class="listview-title">
            Invite your friends to use the EarnRelai Wallet and gain 10% of any crypto they receive
        </p>

        <!-- Modals -->
        <update-profile/>
        <update-password/>
        <update-currency/>
        <two-fa @updated="twoFA_value=$event"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import updateProfile from "../components/modals/updateProfile";
import updatePassword from "../components/modals/updatePassword";
import updateCurrency from "../components/modals/updateCurrency";
import twoFa from "../components/modals/twoFA";

export default {
    name: "Settings",
    data(){
        return {
            // twoFA_enabled: false,
            twoFA_value: !!this.$store.getters['user/getUser'].data.tfa_enabled
        }
    },
    watch: {
        twoFA_value(){
            $('#twoFA').modal('show');
            setTimeout(()=>{
                this.twoFA_value = !!this.$store.getters['user/getUser'].data.tfa_enabled;
            }, 1000)
        }
    },
    computed: {
        ...mapGetters('user', [
            'getUser'
        ])
    },
    methods: {
        copyReferralLink(){
            const site_name = `${window.location.protocol}//${window.location.host}`;
            const ref_link = `${site_name}${this.$router.resolve({name: 'Register', params: {userId: this.$store.getters['user/getUser'].id}}).href}`;
            navigator.clipboard.writeText(ref_link);
            return this.$store.dispatch('notification/display_noti', {
                message: 'Copied to clipboard!',
                timeout: 1000
            });
        },
        verifyEmail(){
            this.$store.dispatch('notification/display_noti', {
                message: 'A verification link will be sent to your email for confirmation. Continue?',
                context: 'icon',
                buttons: [
                    {
                        name: 'Send the link',
                        callback: async ()=>{
                            await this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
                            this.$loader.show();
                            let response = await this.$store.dispatch('auth/verifyEmail');
                            this.$loader.hide();
                            if(response.status){
                                console.log(response.data);
                                return this.$store.dispatch('notification/display_noti', {
                                    message: 'Verification link sent!',
                                })
                            }else{
                                return this.$store.dispatch('notification/display_noti', {
                                    message: response.message,
                                })
                            }
                        }
                    },
                    {
                        name: 'Cancel',
                        callback: ()=>this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
                    }
                ]
            });

        },
        processPassPhraseEmail(){
            this.$store.dispatch('notification/display_noti', {
                message: 'Your passphrase will be sent to your email. Continue?',
                context: 'icon',
                buttons: [
                    {
                        name: 'Proceed',
                        callback: async ()=>{
                            await this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
                            this.$loader.show();
                            let response = await this.$store.dispatch('auth/sendPassPhraseEmail');
                            this.$loader.hide();
                            if(response.status){
                                return this.$store.dispatch('notification/display_noti', {
                                    message: 'Your passphrase have been sent to your email',
                                })
                            }else{
                                return this.$store.dispatch('notification/display_noti', {
                                    message: response.message,
                                })
                            }
                        }
                    },
                    {
                        name: 'Cancel',
                        callback: ()=>this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
                    }
                ]
            });

        }
    },
    components: {
        updateProfile,
        updatePassword,
        updateCurrency,
        twoFa
    }
}
</script>

<style scoped>
.image-listview>li a.item:after{
    content: "";
}
</style>