<template>
    <div class="modal fade action-sheet" id="updateCurrency" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Update Currency
                        <a class="float-end" href="javascript:void(0)" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <form @submit.prevent="update">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="curr">Currency</label>
                                    <select id="curr" class="form-control" v-model="tmp_currency">
                                        <option :value="curr[0]" v-for="(curr, idx) in selected_currency" :key="idx">
                                            {{curr[1].name}} ({{curr[1].symbol}})
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block">
                                    <ion-icon name="save-outline"></ion-icon> Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import User from "../../models/user";

export default {
    name: "updateCurrency",
    data(){
        return {
            tmp_currency: ''
        }
    },
    computed: {
        selected_currency: {
            get: function(){
                return Object
                    .entries(this.$globalFunc.fiats)
                    .filter(curr=>{
                        return curr[0] === 'USD' || curr[0] === 'EUR' || curr[0] === 'GBP' || curr[0] === 'CNY' || curr[0] === 'CZK'
                    })
            },
            set: function(value){
                this.tmp_currency = value
            }
        }
    },
    methods: {
        resetCurrency(){
            const tmp_user = new User(this.$store.getters['user/getUser'].data).storeToModel();
            this.tmp_currency = tmp_user.currency;
        },
        async update(){
            if(this.tmp_currency){
                const user_id = this.$store.getters['user/getUser'].id;
                this.$loader.show();
                const response = await this.$store.dispatch('user/updateUser', {
                    userId: user_id,
                    userData: {
                        currency: this.tmp_currency
                    }
                });
                this.$loader.hide();
                $('#updateCurrency').modal('hide');
                if(response.status){
                    await this.$store.dispatch('user/initUser', {
                        user_id,
                        profile_id: user_id
                    });
                    return this.$store.dispatch('notification/display_noti', {
                        message: 'Currency updated',
                        timeout: 1500
                    });
                }else{
                    return this.$store.dispatch('notification/display_noti', {
                        message: response.message,
                    });
                }
            }
        }
    },
    mounted() {
        this.resetCurrency()
        const modal = $('#updateCurrency');
        modal.on('hidden.bs.modal', ()=>{
            this.resetCurrency()
        })
    }
}
</script>

<style scoped>

</style>