<template>
    <div class="modal fade action-sheet" id="updatePassword" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Update Password
                        <a class="float-end" href="javascript:void(0)" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <div class="col-12 mb-4">
                            <div class="alert alert-outline-warning text-center">
                                <ion-icon name="information-circle-outline"></ion-icon>
                                For security reasons you will be required to re-login once this change has been effected!
                            </div>
                        </div>
                        <form @submit.prevent="update">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="pass">New password</label>
                                    <input type="password" v-model="password" id="pass" class="form-control" placeholder="*********">
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="repass">Retype password</label>
                                    <input type="password" v-model="repass" id="repass" class="form-control" placeholder="*********">
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block">
                                    <ion-icon name="save-outline"></ion-icon> Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "updatePassword",
    data(){
        return {
            password: '', repass: ''
        }
    },
    methods: {
        async update(){
            if(this.password && this.repass){
                if(this.password.length <= 7){
                    return this.$store.dispatch('notification/display_noti', {
                        message: 'Your password should be greater than 8 characters',
                        timeout: 1500
                    });
                }else if(this.password !== this.repass){
                    return this.$store.dispatch('notification/display_noti', {
                        message: 'Passwords do not match!',
                        timeout: 1500
                    });
                }else {
                    this.$loader.show();
                    const response = await this.$store.dispatch('user/updateUserPassword', this.password);
                    this.$loader.hide();
                    if(response.status){
                        $('#updatePassword').modal('hide');
                        await this.$store.dispatch('notification/display_noti', {
                            message: 'Password updated!',
                            timeout: 1500
                        });
                        setTimeout(()=>{
                            this.$store.dispatch('auth/logout')
                        }, 2000)
                    }else{
                        return this.$store.dispatch('notification/display_noti', {
                            message: response.message,
                        });
                    }
                }
            }
        }
    },
    mounted() {
        const modal = $('#updatePassword');
        modal.on('hidden.bs.modal', ()=>{
            this.password = this.repass = "";
        })
    }
}
</script>

<style scoped>

</style>