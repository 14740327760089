<template>
    <div class="modal fade action-sheet" id="updateProfile" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Update Profile
                        <a class="float-end" href="javascript:void(0)" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <form @submit.prevent="update">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="uname">Username</label>
                                    <input type="text" v-model="profile.name" id="uname" class="form-control" placeholder="username">
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="uname">Email</label>
                                    <input type="email" v-model="profile.email" id="email" class="form-control" placeholder="email">
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="phone">Phone</label>
                                    <div class="row mt-2">
                                        <div class="col-2">
                                            <vue-country-code @onSelect="onSelect"></vue-country-code>
                                        </div>
                                        <div class="col-9 offset-1">
                                            <input type="tel" v-model="profile.phone" id="phone" class="form-control" placeholder="telephone">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="address">Home Address</label>
                                    <input type="text" v-model="profile.address" id="address" class="form-control" placeholder="address">
                                </div>
                            </div>


                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block">
                                    <ion-icon name="save-outline"></ion-icon> Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import User, {UserModel} from "../../models/user";
import {mapGetters} from "vuex"

export default {
    name: "updateProfile",
    data(){
        return {
            profile: new UserModel(),
            dial_code: ''
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser'
        })
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        onSelect({name, iso2, dialCode}){
            this.dial_code = dialCode;
            // console.log(name, iso2, dialCode);
        },
        async update(){
            if(!this.profile.name || !this.profile.email){
                return this.$store.dispatch('notification/display_noti', {
                    message: 'The username or email field can not be left blank',
                    timeout: 1500
                });
            }
            if(this.profile.email !== this.user.data.email){
                this.profile.email_is_verified = false;
            }
            this.$loader.show();

            if(this.profile.phone.startsWith('0')){
                let tmp_phone = this.profile.phone.split("").splice(1, this.profile.phone.length).join("");
                this.profile.phone = '+' + this.dial_code + tmp_phone
            }else if(!this.profile.phone.startsWith('+')){
                this.profile.phone = '+' + this.dial_code + this.profile.phone
            }

            const response = await this.$store.dispatch('user/updateUser', {
                userId: this.user.id,
                userData: this.profile
            });
            this.$loader.hide();
            if(response.status){
                await this.$store.dispatch('user/initUser', {
                    user_id: this.user.id,
                    profile_id: this.user.id
                });
                $('#updateProfile').modal('hide');
                return this.$store.dispatch('notification/display_noti', {
                    message: 'Profile updated',
                    timeout: 1500
                });
            }else{
                return this.$store.dispatch('notification/display_noti', {
                    message: response.message,
                });
            }
        },
        resetProfile(){
            this.profile = new User(this.user.data).storeToModel();
        }
    },
    mounted() {
        this.resetProfile();
        const modal = $('#updateProfile');
        modal.on('hidden.bs.modal', ()=>{
            setTimeout(()=>{
                this.resetProfile();
            }, 2000)
        })
    }
}
</script>

<style scoped>

</style>