<template>
    <div class="modal fade dialogbox" id="twoFA" data-bs-backdrop="static" tabindex="-1"
         role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-icon text-success">
                    <ion-icon name="shield-checkmark-outline"></ion-icon>
                </div>
                <div class="modal-header">
                    <h5 class="modal-title">2FA</h5>
                </div>
                <div class="modal-body">
                    <template v-if="requireTel">
                        <div class="alert alert-outline-primary text-center">
                            To enable 2FA you need to provide a valid phone number. <br>
                            You will not be able to log into your RelaiEarn Wallet if you provide an invalid phone number
                        </div>
                        <div class="form-group mt-3">
                            <label class="form-label">Phone <small class="text-info">e.g +12345678910</small></label>
                            <input type="tel" class="form-control" placeholder="" v-model="tel">
                        </div>
                    </template>
                    <template v-else>
                        <p>
                            2 Factor Authentication adds an extra security layer to your account. Someone can have your password
                            but still be unable to have access to your wallet's account
                        </p>
                        <p>
                            We encourage all users to enable 2FA on their RelaiEarn Wallet
                        </p>
                    </template>

                </div>
                <div class="modal-footer">
                    <div class="btn-inline" v-if="!requireTel">
                        <a href="javascript:void(0)" class="btn" data-bs-dismiss="modal">Close</a>
                        <a @click="proceed('disable')" href="javascript:void(0)" class="btn" v-if="user.data.tfa_enabled">
                            <div v-if="semaphore===0" class="spinner-grow text-primary" role="status"></div>
                            <span v-else>Disable</span>
                        </a>
                        <a @click="proceed('enable')" href="javascript:void(0)" class="btn" v-else>
                            <div v-if="semaphore===0" class="spinner-grow text-primary" role="status"></div>
                            <span v-else>Enable</span>
                        </a>
                    </div>
                    <div class="btn-inline" v-else>
                        <a href="javascript:void(0)" class="btn" data-bs-dismiss="modal">Close</a>
                        <a @click="savePhone" href="javascript:void(0)" class="btn">
                            <div v-if="semaphore===0" class="spinner-grow text-primary" role="status"></div>
                            <span v-else>Proceed</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: "twoFA",
    data(){
        return {
            requireTel: false,
            tel: '',
            semaphore: 1,
        }
    },
    computed: {
      ...mapGetters({
          user: 'user/getUser'
      })
    },
    methods: {
        proceed(action='enable'){
            if(!this.user.data.phone){
                this.requireTel = true;
            }else{
                this.continueOp(action)
            }
        },
        async savePhone(){
            if(this.tel.length > 4){
                if(this.semaphore === 1){
                    this.semaphore = 0;
                    const response = await this.$store.dispatch('user/updateUser', {
                        userId: this.user.id,
                        userData: {phone: this.tel}
                    });
                    this.semaphore = 1;
                    if(response.status){
                        return this.continueOp('enable')
                    }else{
                        return this.$store.dispatch('notification/display_noti', {
                            message: response.message,
                        })
                    }
                }
            }
        },
        async continueOp(action){
            if(this.semaphore === 1){
                this.semaphore = 0;
                const response = await this.$store.dispatch('user/updateUser', {
                    userId: this.user.id,
                    userData: {tfa_enabled: action==='enable'}
                });
                this.semaphore = 1;
                if(response.status){
                    $('#twoFA').modal('hide');
                    await this.$store.dispatch('notification/display_noti', {
                        message: 'Operation successful!',
                    });
                    await this.$store.dispatch('user/initUser', {
                        user_id: this.user.id,
                        profile_id: this.user.id
                    })
                    return this.$emit('updated', action==='enable')
                }else{
                    return this.$store.dispatch('notification/display_noti', {
                        message: response.message,
                    })
                }
            }

        }
    },
    mounted() {
        $('#twoFA').on('hidden.bs.modal', ()=>{
            this.requireTel = false;
            this.tel = '';
            this.$emit('closed')
        })
    }
}
</script>

<style scoped>

</style>